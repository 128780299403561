import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";
import { usePermission } from "../../components/usePermission";
import Loader from "../../components/Control/Loader";
import formatDate from "../../components/Control/formatDate";
import useConfirm from "../../components/Control/useConfirm";
import { useQuery } from "../../components/Control/useQuery";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});
const Editable = ({ item, DeletePir, partId, partName, partNumber }) => {
  const permissions = usePermission(22);
  return (
    <TableCell align="center">
      <Tooltip title="PIR" arrow>
        {permissions?.includes("view") ? (
          <Link
            to={`/supervisor/CreateEditPir/${partId}/${item._id}?partNumber=${partNumber}&partName=${partName}&isView=true`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{ fontSize: "1.4rem", color: "#003566" }}
              />
            </IconButton>
          </Link>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>

      {permissions?.includes("edit") && (
        <Tooltip title="Edit" arrow>
          <Link
            to={`/supervisor/CreateEditPir/${partId}/${item._id}?partNumber=${partNumber}&partName=${partName}&isView=false`}
          >
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <EditIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
            </IconButton>
          </Link>
        </Tooltip>
      )}
      {permissions?.includes("delete") && (
        <Tooltip title="Delete" arrow>
          <IconButton
            size="small"
            style={{ marginLeft: "0.5rem" }}
            onClick={() => DeletePir(item._id)}
          >
            <DeleteIcon
              style={{
                fontSize: "1.4rem",
                color: "red",
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
};
const NotEditable = ({ item, partId, partName, partNumber }) => {
  const query = useQuery();
  const permissions = usePermission(22);

  return (
    <TableCell align="center">
      <Tooltip title="PIR" arrow>
        {permissions?.includes("view") ? (
          <Link
            to={`/supervisor/CreateEditPir/${partId}/${item._id}?partNumber=${partNumber}&partName=${partName}&isView=true`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{ fontSize: "1.4rem", color: "#003566" }}
              />
            </IconButton>
          </Link>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>
      <Tooltip title="Block" arrow>
        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
          <BlockIcon
            style={{
              fontSize: "1.4rem",
              color: "red",
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Block" arrow>
        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
          <BlockIcon
            style={{
              fontSize: "1.4rem",
              color: "red",
            }}
          />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

const superviserStatus = (
  partId,
  item,
  isSubmit,
  adminRequestStatus,
  DeletePir,
  DialogDelete,
) => {
  //notsubmitted
  if (!isSubmit) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#1864ab",
            color: "white",
          }}
        >
          <span>SAVED</span>
        </TableCell>
        <Editable
          item={item}
          DeletePir={DeletePir}
          partId={partId}
          partName={item?.part_id?.part_name}
          partNumber={item?.part_id?.part_number}
        />
        <DialogDelete isSubmitContent={false} />
      </>
    );
  }

  if (adminRequestStatus === "pending" && isSubmit) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#fdd835",
            color: "black",
            maxWidth: "200px",
          }}
        >
          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "Center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            PENDING FOR APPROVAL
          </span>
        </TableCell>
        <NotEditable
          item={item}
          partId={partId}
          partName={item?.part_id?.part_name}
          partNumber={item?.part_id?.part_number}
        />
      </>
    );
  }
  if (adminRequestStatus === "accepted" && isSubmit) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#2b8a3e",
            color: "#fff",
          }}
        >
          <span>APPROVED</span>
        </TableCell>
        <NotEditable
          item={item}
          partId={partId}
          partName={item?.part_id?.part_name}
          partNumber={item?.part_id?.part_number}
        />
      </>
    );
  }

  if (adminRequestStatus === "rejected" && isSubmit) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#f03e3e",
            color: "#fff",
          }}
        >
          <span>SENT BACK FOR RECHECKING</span>
        </TableCell>
        <Editable
          item={item}
          DeletePir={DeletePir}
          partId={partId}
          partName={item?.part_id?.part_name}
          partNumber={item?.part_id?.part_number}
        />
      </>
    );
  }
};

function SupervisorPir() {
  const classes = useStyles();
  const [pirListData, setPirListData] = useState(null);
  const [render, setRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE PIR",
    "Are you sure you want to delete this PIR?",
  );

  useEffect(() => {
    if (selectedDate) {
      setIsLoading(true);
      axios
        .get(`/api/getAllMonthlyPirList/supervisor/${selectedDate}`)
        .then((res) => {
          setPirListData(
            res.data.sort((a, b) =>
              a.pirReport_date.localeCompare(b.pirReport_date),
            ),
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [render, selectedDate]);

  const DeletePir = async (id) => {
    let isDeleted = await confirmDelete();
    if (isDeleted) {
      axios
        .delete(`/api/deleteOnePir/${id}`)
        .then(() => {
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ paddingBottom: "3rem" }}>
      <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate}
          onChange={setSelectedDate}
        />
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Username
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "600" }}>
              M/C. NO.
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "600" }}>
              P.O. NO.
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Date
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Status
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {pirListData && (
          <TableBody>
            {pirListData.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item?.part_id?.part_number}
                  </TableCell>
                  <TableCell
                    style={{ textTransform: "capitalize" }}
                    align="center"
                  >
                    {item.report_prepared_by}
                  </TableCell>
                  <TableCell align="center">{item?.mc_no}</TableCell>
                  <TableCell align="center">{item?.po_no}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.pirReport_date)}
                  </TableCell>
                  {superviserStatus(
                    item?.part_id?._id,
                    item,
                    item.isSubmitted,
                    item.is_admin_request,
                    DeletePir,
                    DialogDelete,
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
    </div>
  );
}

export default SupervisorPir;
