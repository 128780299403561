import ExcelJS from 'exceljs';

interface BreakloadItem {
  s_no: number;
  lot_code?: string;
  coil_id?: string;
  size?: number | null;
  ts_breakload?: number | null;
  _id?: string;
}

interface HeaderInfo {
  headerRowIndex: number;
  columns: {
    batchSerial: number;
    observedSize: number;
    observedBLTS: number;
  };
}

export const processExcelFile = (file: File): Promise<{ breakloads: Partial<BreakloadItem>[] }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event: ProgressEvent<FileReader>) => {
      try {
        // Load workbook
        const buffer = event.target?.result as ArrayBuffer;
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);
        
        // Get first worksheet
        const worksheet = workbook.worksheets[0];
        if (!worksheet) {
          throw new Error("No worksheet found");
        }

        // Find header row
        let headerRow = -1;
        let batchSerialCol = -1;
        let sizeCol = -1;
        let breakloadCol = -1;

        worksheet.eachRow((row, rowNumber) => {
          if (headerRow !== -1) return;
          
          const values = row.values as (string | undefined)[];
          values.forEach((value, index) => {
            if (!value) return;
            const cellValue = value.toString().trim();
            
            if (cellValue === "Batch/Serial") batchSerialCol = index;
            if (cellValue === "Observed Size") sizeCol = index;
            if (cellValue === "Observed BL/TS") breakloadCol = index;
          });

          if (batchSerialCol !== -1 || sizeCol !== -1 || breakloadCol !== -1) {
            headerRow = rowNumber;
          }
        });

        if (headerRow === -1) {
          throw new Error("Could not find header row");
        }

        // Process rows until an empty row is encountered
        const breakloads: Partial<BreakloadItem>[] = [];
        let currentRow = headerRow + 1;
        let rowCount = 0;

        while (true) {
          const row = worksheet.getRow(currentRow);
          
          // Check if row is empty
          const batchSerialValue = row.getCell(batchSerialCol)?.value;
          const sizeValue = row.getCell(sizeCol)?.value;
          const breakloadValue = row.getCell(breakloadCol)?.value;

          // Stop if all relevant cells are empty
          if (!batchSerialValue && !sizeValue && !breakloadValue) {
            break;
          }

          // Create breakload item
          const breakloadItem: Partial<BreakloadItem> = {
            s_no: rowCount + 1
          };

          // Process Batch/Serial if column exists
          if (batchSerialCol !== -1) {
            const batchSerial = row.getCell(batchSerialCol)?.value?.toString().trim();
            if (batchSerial) {
              const parts = batchSerial.split('/');
              if (parts.length >= 2) {
                breakloadItem.lot_code = parts.slice(0, 2).join('/');
                breakloadItem.coil_id = parts.slice(2).join('/');
              }
            }
          }

          // Process Size if column exists
          if (sizeCol !== -1) {
            const sizeValue = row.getCell(sizeCol)?.value;
            breakloadItem.size = typeof sizeValue === 'number' ? sizeValue :
              sizeValue ? parseFloat(sizeValue.toString()) || null : null;
          }

          // Process Breakload if column exists
          if (breakloadCol !== -1) {
            const breakloadValue = row.getCell(breakloadCol)?.value;
            breakloadItem.ts_breakload = typeof breakloadValue === 'number' ? breakloadValue :
              breakloadValue ? parseFloat(breakloadValue.toString()) || null : null;
          }

          breakloads.push(breakloadItem);
          rowCount++;
          currentRow++;
        }

        resolve({ breakloads });

      } catch (error) {
        console.error("Error processing Excel file:", error);
        reject(error);
      }
    };

    reader.onerror = (error: ProgressEvent<FileReader>) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};